import React, { useState } from "react";
import axios from "axios";
import "./Register.css";

const Register = () => {
  async function register(e) {
    try {
      e.preventDefault();
      const registerData = {
        firstname,
        lastname,
        email,
        password,
        passwordVerify,
        admin,
      };

      await axios.post(
        "https://palatium-fastigheter.herokuapp.com/auth/",
        registerData
      );
    } catch (err) {
      console.error(err);
    }
  }
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVerify, setPasswordVerify] = useState("");
  const admin = false;

  return (
    <div className="register">
      <h1>Registrering</h1>
      <form onSubmit={register}>
        <input
          size="40"
          type="firstname"
          placeholder="Förnamn"
          onChange={(e) => setFirstname(e.target.value)}
          value={firstname}
          id="firstname"
        />

        <input
          size="40"
          type="lastname"
          placeholder="Efternamn"
          onChange={(e) => setLastname(e.target.value)}
          value={lastname}
          id="lastname"
        />

        <input
          size="40"
          type="email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />

        <input
          size="40"
          type="password"
          placeholder="Lösenord"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />

        <input
          size="40"
          type="password"
          placeholder="Verifiera Lösenord"
          onChange={(e) => setPasswordVerify(e.target.value)}
          value={passwordVerify}
        />
        <button type="submit">Skapa Konto</button>
      </form>
    </div>
  );
};

export default Register;
