import React, { useState, useEffect, createContext } from "react";
import axios from "axios";

//©PALATIUM FASTIGHETER 2021-2021

const EmailContext = createContext();

function EmailContextProvider(props) {
  const [email, setEmail] = useState(undefined);

  async function getEmail() {
    const EmailRes = await axios.get(
      "https://palatium-fastigheter.herokuapp.com/auth/usergetemail"
    );
    setEmail(EmailRes.data);
  }

  useEffect(() => {
    getEmail();
  }, []);

  return (
    <EmailContext.Provider value={{ getEmail, email }}>
      {props.children}
    </EmailContext.Provider>
  );
}

export default EmailContext;
export { EmailContextProvider };
