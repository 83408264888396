import React from "react";
import { List, ListItem } from "@material-ui/core";
import "./RentalInfo.css";

const RentalInfo = () => {
  return (
    <div className="rentalinfo-container">
      <div>
        <h1>Felanmälan</h1>
        <p>Felanmälan som inte är akut görs till: info@palatium.se</p>
        <h2>Fastighetsjour</h2>
        <p>
          Om du inte får tag på oss vid akuta händelser (exempelvis
          översvämning/vattenskada). Kontakta Securitas fastighetsjour på
          telefon: 010 470 57 70
        </p>
        <h2>Vid utelåsning </h2>
        <p>
          Kontakta Securitas fastighetsjour på telefon: 010 470 57 70 Securitas
          debiterar en utryckningsavgift för låsjour.
        </p>
        <h2>SOS </h2>
        <p>Vid akuta nödsituationer med fara för liv ring SOS Alarm: 112.</p>
        <p>
          Fel och brister i bostad eller andra delar av fastigheten ska alltid
          felanmälas.{" "}
        </p>
        <p>
          Särskilt viktigt att ringa oss eller fastighetsjour utan dröjsmål om
          det gäller fel som kan orsaka skada på människor, fastigheten eller
          miljö.
        </p>
      </div>
      <div>
        <h1>Uthyrningspolicy</h1>
        Palatium fastigheter har inget kösystem utan väljer den hyresgäst som vi
        finner mest lämplig för att hyra bostaden. Följande grundkrav gäller för
        att teckna hyreskontrakt:
        <List>
          <ListItem>
            Uthyrning sker genom HomeQ där man som sökande skapar ett konto.
          </ListItem>
          <ListItem>
            • Den/de som söker bostad hos oss måste ha fyllt 18 år.
          </ListItem>
          <ListItem>
            • Sökande måste ha en fast inkomst från anställning,
            näringsverksamhet eller ålderspension.
          </ListItem>
          <ListItem>
            • Inkomst ska styrkas med intyg. Enligt konsumentverkets riktlinjer
            ska ungefär 1/3 av inkomsten gå till boendekostnader.
          </ListItem>
          <ListItem>• Försörjningsstöd räknas inte som inkomst.</ListItem>
          <ListItem>
            • Sökande får inte ha betalningsanmärkningar eller skulder hos
            Kronofogden.
          </ListItem>
          <ListItem>
            • Den sökande ska ha goda referenser från tidigare hyresvärd.
          </ListItem>
          <ListItem>
            • För att förhindra osunda boendeförhållanden genom trångboddhet
            finns en rekommendation om hur många som får bo i en lägenhet.
          </ListItem>
        </List>
        <List>
          <ListItem>1 rum och kök, max två personer</ListItem>
          <ListItem>2 rum och kök, max tre personer</ListItem>
          <ListItem>3 rum och kök, max fem personer</ListItem>
          <ListItem>4 rum och kök, max sex personer</ListItem>
          <ListItem>5 rum och kök eller större, max åtta personer</ListItem>
        </List>
      </div>
    </div>
  );
};

export default RentalInfo;
