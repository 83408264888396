import React, { useState, useEffect, createContext } from "react";
import axios from "axios";

const AuthContext = createContext();

function AuthContextProvider(props) {
  const [loggedIn, setLoggedIn] = useState(undefined);
  const [admin, setAdmin] = useState(undefined);
  const email = {
    email: localStorage.getItem("email"),
  };

  async function getLoggedIn() {
    const loggedInRes = await axios.get(
      "https://palatium-fastigheter.herokuapp.com/auth/loggedIn"
    );
    setLoggedIn(loggedInRes.data);
  }

  async function getAdmin() {
    const adminRes = await axios.get(
      "https://palatium-fastigheter.herokuapp.com/auth/admin",
      email
    );
    if (adminRes.data === true) {
      setAdmin(adminRes.data);
    }
  }

  useEffect(() => {
    getLoggedIn();
    getAdmin();
  });

  return (
    <AuthContext.Provider value={{ loggedIn, getLoggedIn, admin, getAdmin }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
export { AuthContextProvider };
