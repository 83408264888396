import React from "react";
import Navbar from "./components/Navbar/Navbar";
import { useContext } from "react";
import AuthContext from "./context/AuthContext";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home/Home";
import { AuthContextProvider } from "./context/AuthContext";
import RegisterPage from "./pages/Register/RegisterPage";
import LedigaBostader from "./pages/LedigaBostader/LedigaBostader";
import RentalInfo from "./pages/RentalInfo/RentalInfo";
import Login from "./components/Login/Login";
import axios from "axios";
import Admin from "./pages/Admin/Admin";
import Bottomheader from "./components/Bottomheader/Bottomheader";
import intresseanmalan from "./pages/Intresseanmalan/Intresseanmalan";

axios.defaults.withCredentials = true;

function App() {
  const { loggedIn } = useContext(AuthContext);

  return (
    <>
      <AuthContextProvider>
        <BrowserRouter>
          <Navbar></Navbar>
          <Switch>
            <Route exact path="/" component={Home}></Route>
            <Route path="/boende" component={LedigaBostader}></Route>
            <Route path="/intresseanmalan" component={intresseanmalan}></Route>
            <Route path="/hyresgaster" component={RentalInfo}></Route>
            <Route path="/loggain" component={Login}></Route>
            {loggedIn === false && (
              <Route
                render={() => (
                  <h1 style={{ textAlign: "center", marginTop: "20%" }}>
                    404: Sidan du letar efter finns inte..
                  </h1>
                )}
              />
            )}
            {loggedIn === true && (
              <Route path="/admin" component={Admin}></Route>
            )}
            <Route path="/register" component={RegisterPage}></Route>
            <Route
              render={() => <h1>404: Sidan du letar efter finns inte..</h1>}
            />
          </Switch>
        </BrowserRouter>
      </AuthContextProvider>
      <Bottomheader />
    </>
  );
}

export default App;
