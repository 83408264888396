import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from "./context/AuthContext";
import { EmailContextProvider } from "./context/EmailContext";

ReactDOM.render(
  <React.StrictMode>
  <AuthContextProvider>
    <EmailContextProvider>
    <App />
    </EmailContextProvider>
  </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


reportWebVitals();
