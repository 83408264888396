import React, { useState, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import "./Navbar.css";
import AuthContext from "../../context/AuthContext";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";

//©PALATIUM FASTIGHETER 2021-2021

const Navbar = () => {
  const { loggedIn } = useContext(AuthContext);
  const { getLoggedIn } = useContext(AuthContext);
  const history = useHistory();

  async function logOut() {
    await axios.get("https://palatium-fastigheter.herokuapp.com/auth/logout");
    await getLoggedIn();
    history.push("/");
    window.location.reload();
  }

  const debounce = (fn) => {
    let frame;

    return (...params) => {
      if (frame) {
        cancelAnimationFrame(frame);
      }
      frame = requestAnimationFrame(() => {
        fn(...params);
      });
    };
  };

  const storeScroll = () => {
    document.documentElement.dataset.scroll = window.scrollY;
  };

  document.addEventListener("scroll", debounce(storeScroll), { passive: true });

  storeScroll();

  const [mobilemenu, setMobileMenu] = useState(false);

  function togglemobilemenu() {
    if (mobilemenu === false) {
      setMobileMenu(true);
    } else {
      setMobileMenu(false);
    }
  }

  return (
    <div>
      <div className="nav">
        <div className="navbar">
          <div className="brand">
            <Link to="/">
              <img alt="" src="palatium-nav-logo3.webp"></img>
            </Link>
          </div>
          <button onClick={togglemobilemenu} className="navmenubtn">
            <i className="fas fa-bars"></i>
          </button>
          <div className="links">
            <NavLink className="link" to="/">
              Hem
            </NavLink>
            <NavLink className="link" to="/boende">
              Lediga bostäder
            </NavLink>
            <NavLink className="link" to="/intresseanmalan">
              Intresseanmälan
            </NavLink>
            <NavLink className="link" to="/hyresgaster">
              För hyresgäster
            </NavLink>
            {loggedIn === true && (
              <NavLink className="link" to="/admin">
                Admin
              </NavLink>
            )}
            {loggedIn === true && (
              <button className="logout" onClick={logOut}>
                Logga ut
              </button>
            )}
          </div>
        </div>
      </div>
      {mobilemenu === true && (
        <div className="mobilemenu">
          <div className="mobile-links">
            <div className="mobile-link">
              <button
                className="mobile-button"
                onClick={() => setMobileMenu(false)}
              >
                <CloseIcon fontSize="large"></CloseIcon>
              </button>
            </div>
            <NavLink
              onClick={() => setMobileMenu(false)}
              className="mobile-link"
              to="/"
            >
              Hem
            </NavLink>
            <NavLink
              onClick={() => setMobileMenu(false)}
              className="mobile-link"
              to="/boende"
            >
              Lediga bostäder
            </NavLink>
            <NavLink
              onClick={() => setMobileMenu(false)}
              className="mobile-link"
              to="/intresseanmalan"
            >
              Intresseanmälan
            </NavLink>
            <NavLink
              onClick={() => setMobileMenu(false)}
              className="mobile-link"
              to="/hyresgaster"
            >
              För hyresgäster
            </NavLink>
            {loggedIn === true && (
              <NavLink
                onClick={() => setMobileMenu(false)}
                className="mobile-link"
                to="/admin"
              >
                Admin
              </NavLink>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
