import React, { Suspense } from "react";
import { Loading } from "../../components/Loading/Loading";
const Intresse = React.lazy(() => import("../../components/Intresse/Intresse"));
const Intresseanmalan = () => {
  return (
    <Suspense
      fallback={
        <div>
          <Loading />
        </div>
      }
    >
      <Intresse></Intresse>
    </Suspense>
  );
};

export default Intresseanmalan;
