import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "./Login.css";

//©PALATIUM FASTIGHETER 2021-2021

const Login = () => {
  const history = useHistory();

  async function login(e) {
    try {
      e.preventDefault();
      const loginData = {
        email,
        password,
      };
      await axios.post(
        `https://palatium-fastigheter.herokuapp.com/auth/login`,
        loginData
      );
      window.location.reload(history.push("/admin"));
    } catch ({ response }) {
      setDisplayError(true);
      setErrorMessage(response.data.errorMessage);
    }
  }

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [displayError, setDisplayError] = useState(false);

  function stopErrorDisplay() {
    setDisplayError(false);
  }

  return (
    <div className="login">
      <div>
        {displayError === true && (
          <div>
            <p className="errorMessage-mobile">
              {errorMessage}
              <button onClick={stopErrorDisplay} className="errorButton-mobile">
                <i class="far fa-times-circle"></i>
              </button>
            </p>
          </div>
        )}
        <div className="login-mobile">
          <h2>Logga in</h2>
          <form onSubmit={login}>
            <input
              size="24"
              type="email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              id="email"
            />
            <input
              size="24"
              type="password"
              id="password"
              placeholder="Lösenord"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <button type="submit">Logga in</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
