import React, { Suspense } from "react";
import { Loading } from "../../components/Loading/Loading";
const HomeQ = React.lazy(() => import("../../components/HomeQ/HomeQ"));

const LedigaBostader = () => {
  return (
    <Suspense fallback={<Loading />}>
      <HomeQ />
    </Suspense>
  );
};

export default LedigaBostader;
