import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Admin.css";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";

//©PALATIUM FASTIGHETER 2021-2021

const Admin = () => {
  const [firstname, setFirstname] = useState("");
  const [intresseForms, setIntresseForms] = useState([]);
  const [curForm, setCurForm] = useState([]);
  const [singleFormView, setSingleFormView] = useState(false);

  async function getData() {
    const res = await axios.get(
      "https://palatium-fastigheter.herokuapp.com/auth/usergetfirstname"
    );
    const resIntresse = await axios.get(
      "https://palatium-fastigheter.herokuapp.com/intresse"
    );
    setFirstname(res.data);
    setIntresseForms(resIntresse.data);
  }

  function getRowForm(itm) {
    setCurForm(itm);
    setSingleFormView(true);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="admin-page">
      <h2 style={{ textAlign: "center" }}>Välkommen tillbaka, {firstname}</h2>
      {singleFormView === true && (
        <div className="admin-singleformview">
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => {
                setSingleFormView(false);
              }}
            >
              <CloseIcon />
            </Button>
          </span>
          <h2>
            {curForm.firstname} {curForm.lastname}
          </h2>
          <p>Personnummer: {curForm.ssn}</p>
          <p>Telefonnummer: +{curForm.phonenumber}</p>
          <p>Ort: {curForm.ort}</p>
          <p>E-post: {curForm.epost}</p>
          <p>Rökare: {curForm.smoker}</p>
          <p>Inkomst: {curForm.inkomst}</p>
          <p>Inkomsttyp: {curForm.inkomsttyp}</p>
          <p>Arbetsgivare: {curForm.arbetsgivare}</p>
          <p>Hyresvärd: {curForm.hyresvard}</p>
          <p>Nummer till hyresvärd: +{curForm.nmrhyresvard}</p>
          <p>Max hyra: {curForm.maxhyra}</p>
          <p>Minst antal rum: {curForm.minroom}</p>
          <p>Barn: {curForm.barn}</p>
          <p>Husdjur: {curForm.husdjur}</p>
          <p>Övrigt: {curForm.personligtbrev}</p>
        </div>
      )}
      {singleFormView === false && (
        <DataGrid
          getRowId={(intresseForms) => intresseForms._id}
          rows={intresseForms}
          onRowClick={(itm) => getRowForm(itm.row)}
          columns={[
            { field: "ssn", headerName: "Personnummer", width: 200 },
            { field: "firstname", headerName: "Förnamn" },
            { field: "lastname", headerName: "Efternamn" },
            { field: "ort", headerName: "Ort" },
            { field: "date", headerName: "Datum" },
          ]}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          autoHeight
        />
      )}
    </div>
  );
};

export default Admin;
