import React from "react";
import "./Bottomheader.css";

const Bottomheader = () => {
  return (
    <div className="bottomheader-container">
      <p>© 2022 PALATIUM FASTIGHETER AB. All rights reserved.</p>
    </div>
  );
};

export default Bottomheader;
